<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="'牧户：' + `${muHuDetailData.nickName || '牧户名称'}`" @selectJob="selectJob" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_5_p h_full flex_column_between">
        <mu-hu-mian-yi-yi-miao-chart 
          countyId="150423000000"
          :townId="townId"
          :villageId="villageId"
          :groupId="groupId"
          :userId="userId" 
          :jobId="jobId"
          vaccinumTypeId="1"
        />
      </div> 

      <!-- 2 -->
      <div class="w_32_5_p h_full flex_column_between ">
        <div class="w_full h_49_p chart_box_bgi_1 bgs_full p_10 border_box">
          <div class="w_full h_60_p relative">
            <div class="w_30_p h_full absolute_center border_radius_10 overflow_hidden relative">
              <!-- 牧户头像 -->
              <img 
                class="w_full border_radius_10 cursor absolute top_0 left_0" 
                v-if="muHuDetailData.avatarUrl"
                :src='filePath + muHuDetailData.avatarUrl' alt=""
              >

              <!-- 默认头像 -->
              <img 
                class="w_full border_radius_10 cursor response_img" 
                v-else
                :src='def_user_img' alt=""
              >
            </div>
          </div>

          <div class="w_full h_40_p color_fff pt_5">
            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">姓名： <span class="main_color">{{ muHuDetailData.nickName }}</span> </div>
              <!-- <div class="h_24 lh_24 mb_3">性别： <span class="main_color">{{ formatGender(muHuDetailData.gender) }}</span> </div>  -->
              <!-- <div class="h_24 lh_24 mb_3">草场面积（㎡）：<span class="main_color font_number fw_bold ls_2">324</span> </div> -->
            </div>

            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">所在嘎查： <span class="main_color">{{ muHuDetailData.townName + muHuDetailData.villageName }}</span> </div>
            </div>

            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">
                电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(muHuDetailData.phone) }}</span>
              </div>
            </div>

            <div 
              class="jian_bian_text_1 fw_bold cursor" 
              @click="goToPage(`/mianYiTaiZhang/${muHuDetailData.nickName}`)"
            >
              免疫记录
            </div>
          </div>
        </div>

        <!-- 普查 -->
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_10">
          <cattle-category-chart 
            :chartData="cattleCategoryChartData"
            @selectPanDian="selectAnimalCategoryJob"
            @selectAnimalCategoryName="selectAnimalCategoryName"
          />
        </div>
      </div> 

      <!-- 3 -->
      <div class="w_32_5_p h_full list_box_bgi_1 bgs_full">
        <div class="full p_10 flex_column_between">
          <div class="w_full text_left">
            <div class="w_100 jian_bian_text_1 text_1 fw_bold">免疫记录</div>
          </div>

          <div class="w_full p_5">
            <div class="w_30_p">
              <el-select v-model="paginationParams.animalId" size="mini" placeholder="免疫动物" @change="selectAnimal">
                <el-option
                  v-for="item in animalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="w_full h_full-60 flex_column_between">
            <div class="scroll_box w_full h_full-30 overflow_y_auto">
              <dv-loading v-if="!recordYiList.length" class="color_fff">数据加载中...</dv-loading>
              <div 
                class="w_full flex_row_between flex_wrap text_1 pv_10 ph_10 border_b_1_s_main_color hover_main_bgc cursor" 
                v-for="(item, index) in recordYiList" :key="index"
                @click="handleClickAnimalName(item.animalId)"
              >
                <div class="w_50_p p_2">
                  <span class="color_fff">免疫日期：</span>
                  <span class="main_color font_number">{{ formatDate(item.createTime) }}</span> 
                </div>
                <div class="w_50_p p_2">
                  <span class="color_fff">防疫员：</span>
                  <span class="main_color">{{ item.antiepidemicPersonName }}</span>
                </div>
                <div class="w_full p_2">
                  <span class="color_fff">免疫疫苗：</span>
                  <span class="theme_color_green">{{ item.vaccinumName }}</span>
                </div>
                <div class="w_50_p p_2">
                  <span class="color_fff">免疫动物类型：</span>
                  <span class="main_color">{{ item.animalName }}</span>
                </div>
                <div class="w_50_p p_2">
                  <span class="color_fff">免疫病种：</span>
                  <span class="main_color">{{ item.ddd }}</span>
                </div>
                <div class="w_50_p p_2">
                  <span class="color_fff">动物存栏量：</span>
                  <span class="main_color font_number">{{ item.animalCount }}</span>
                </div>
                <div class="w_50_p p_2">
                  <span class="color_fff">免疫动物数量：</span>
                  <span class="main_color font_number">{{ item.fangYiCount }}</span>
                </div>
              </div>
            </div> 

            <!-- 分页 -->
            <div class="w_full h_35">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :page-sizes="[50, 100, 200, 300]"
                layout="total, sizes, prev, pager, next"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>

<script>
  import defMixin from '@/mixins/def.js'
  import componentsMixin from '@/mixins/components.js'
  import geoJsonMapMixin from '@/mixins/geoJson-map.js'
  import imgMixin from '@/mixins/def-img.js'
  import echartMixin from '@/mixins/echart.js'
  import dataChartMixin from '@/mixins/data-charts.js'
  
  import { 
    getUserDetail, 
    getBreedingRecordsListByOwnerId,
    getRecordsBingListByOwnerId,
    getCheckList
  } from '@/api/mu-hu/mu-hu.js'

  import { filterRecordsYiCount } from '@/api/fang-yi/fang-yi.js'
  import { getAnimalList } from '@/api/animal/index.js'
  
  export default {
    name: 'MuHuPage',
    mixins: [defMixin, componentsMixin, geoJsonMapMixin, imgMixin, echartMixin, dataChartMixin],
    data() {
      return {
        panDianId: null,
        animalPanDianData: [],

        cunLanCount: null,
        fangYiCount: null,
        fangYiYuanCount: null,
        muHuCount: null,

        jobId: null,

        townId: null,
        villageId: null,
        groupId: null,
        owner: null,
        userId: null,

        filterFangYiYuan: '',
        fangYiYuanList: [],

        fangYiType: '',
        animalId: null,
        animalList: [],

        cattleCategoryChartData: {
          unit: '头',
          isShowUnit: true,
          animalList: [],
          data: []
        },

        paginationParams: {
          userId: null,
          jobId: null,
          animalId: null,
          page: 1,
          size: 50
        },

        recordYiList: [],
        total: 0,

        muHuDetailData: {},
        cattleList: [],
        mianYiList: [],
        mianYiRes: {}
      }
    },
    async mounted() {
      this.jobList = JSON.parse(sessionStorage.getItem('jobList'))

      

      let townId = this.$route.params.townId || ''
      let villageId = this.$route.params.villageId || ''
      let groupId = this.$route.params.groupId || ''
      let ownerId = this.$route.params.ownerId

      this.townId = townId
      this.villageId = villageId
      this.groupId = groupId
      this.userId = parseInt(ownerId)

      this.paginationParams.userId = this.$route.params.ownerId

      // 通过牧户 id 获取 牧户详情信息
      await this.getMuHuDetail(ownerId)

      // 获取动物列表
      await this.getAnimalList()

      // 获取 牧户的 免疫记录
      await this.filterRecordsYiCount()

      // 获取 牧户的 动物存栏 
      await this.getBreedingRecordsListByOwnerId(ownerId)

      // 获取 牧户的 动物免疫
      await this.getRecordsBingListByOwnerId(ownerId)
    },
    methods: {
      selectJob(jobId) {
        this.jobId = jobId;
        this.paginationParams.jobId = jobId

        this.filterRecordsYiCount()
      },

      // 获取牧户详情
      async getMuHuDetail(id) {
        let res = await getUserDetail(id)
        try {
          if (res.code == 1000) {
            this.muHuDetailData = res.data
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 获取动物列表
      async getAnimalList() {
        let res = await getAnimalList()
        try {
          if (res.code == 1000) {
            this.animalList = res.data
            this.animalId = res.data[0].id
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 盘点 信息
      async getCheckList(userId, panDianId) {
        let params = { userId, panDianId }
        let res = await getCheckList(params)
        try {
          if (res.code == 1000) {
            this.animalPanDianData = this.formatAnimalList(res.data)
            let animalList = []

            let list = this.formatAnimalList(res.data.filter(item => item.animalName != '山羊'))

            this.initAnimalCategoryCount(list[0].animalName, list[0])

            for(let i in list) {
              animalList.push({ id: list[i].animalId, name: list[i].animalName})
            }

            this.cattleCategoryChartData.animalList = animalList
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 监听 盘点 job
      selectAnimalCategoryJob(panDianId) {
        this.panDianId = panDianId

        this.getCheckList(this.userId, panDianId)
      },

      // 监听 盘点 牲畜
      selectAnimalCategoryName(animalId) {
        this.animalPanDianData.map(item => {
          if (item.animalId == animalId) {
            this.initAnimalCategoryCount(item.animalName, item)
          }
        })
      },

      // 获取 免疫台账信息
      async filterRecordsYiCount() {
        let res = await filterRecordsYiCount(this.paginationParams)
        try {
          if (res.code == 1000) {
            this.recordYiList = res.data.list
            this.total = res.data.pagination.total
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 获取牧户的动物存栏
      async getBreedingRecordsListByOwnerId(ownerId) {
        let res = await getBreedingRecordsListByOwnerId(ownerId)
        try {
          if (res.code == 1000) {
            this.cattleList = res.data
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 获取牧户的免疫存栏
      async getRecordsBingListByOwnerId(ownerId) {
        let res = await getRecordsBingListByOwnerId(ownerId)
        try {
          if (res.code == 1000) {
            this.mianYiList = res.data
          }
        } catch (err) {
          console.log(err)
        }
      },

      // 免疫记录 牲畜类型
      selectAnimal(val) {
        this.paginationParams.animalId = val

        this.filterRecordsYiCount()
      },

      // 点击 疫苗
      handleClickYiMiao(item) {
        this.goToVacciumnDetail(item.vaccinumId, item.vaccinumName, item.vaccinumTypeId)
      },

      // 点击 动物名称
      handleClickAnimalName(animalId) {
        // let userId = this.muHuDetailData.id
        // let userName = this.muHuDetailData.nickName

        // // 跳转到 动物防疫列表 页面
        // this.$router.push({
        //   path: `/animalFangYiList/${userId}/${userName}/${animalId}`
        // })
      },

      handlePageChange(val) {
        this.paginationParams.page = val
        this.filterRecordsYiCount()
      },

      handleSizeChange(val) {
        this.paginationParams.size = val
        this.filterRecordsYiCount()
      },

      // 格式化 牲畜 顺序 
      formatAnimalList(arr) {
        let sortdict = {
          "牛": 1,
          "羊": 2,
          "猪": 3,
          "禽": 4,
          "犬": 5,
          "马": 6,
          "驴": 7,
          "骆驼": 8
        }
        return arr.sort((a, b) => sortdict[a.animalName] - sortdict[b.animalName])
      },

      // 格式化 动物 分类
      initAnimalCategoryCount(animalName, obj) {
        if (animalName == '牛') {
          this.cattleCategoryChartData.unit = '头'
          this.cattleCategoryChartData.data = [
            {name: '种公牛', value: obj.maleCount, unit: ''},
            {name: '乳牛', value: obj.femaleCount, unit: ''},
            {name: '犊牛', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } else if (animalName == '羊') {
          this.cattleCategoryChartData.unit = '只'
          this.cattleCategoryChartData.data = [
            {name: '种公羊', value: obj.maleCount, unit: ''},
            {name: '母羊', value: obj.femaleCount, unit: ''},
            {name: '羔羊', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } else if (animalName == '猪') {
          this.cattleCategoryChartData.unit = '头'
          this.cattleCategoryChartData.data = [
            {name: '公猪', value: obj.maleCount, unit: ''},
            {name: '母猪', value: obj.femaleCount, unit: ''},
            {name: '猪仔', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } else if (animalName == '犬') {
          this.cattleCategoryChartData.unit = '条'
          this.cattleCategoryChartData.data = [
            {name: '公犬', value: obj.maleCount, unit: ''},
            {name: '母犬', value: obj.femaleCount, unit: ''},
            {name: '狗仔', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } else if (animalName == '禽') {
          this.cattleCategoryChartData.unit = '只'
          this.cattleCategoryChartData.data = [
            {name: '禽', value: obj.totalCount, unit: ''}
          ]
        } else if (animalName == '马') {
          this.cattleCategoryChartData.unit = '匹'
          this.cattleCategoryChartData.data = [
            {name: '种公马', value: obj.maleCount, unit: ''},
            {name: '母马', value: obj.femaleCount, unit: ''},
            {name: '马驹', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } else if (animalName == '驴') {
          this.cattleCategoryChartData.unit = '头'
          this.cattleCategoryChartData.data = [
            {name: '种公驴', value: obj.maleCount, unit: ''},
            {name: '母驴', value: obj.femaleCount, unit: ''},
            {name: '小驴', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } else if (animalName == '骆驼') {
          this.cattleCategoryChartData.unit = '匹'
          this.cattleCategoryChartData.data = [
            {name: '种公骆驼', value: obj.maleCount, unit: ''},
            {name: '母骆驼', value: obj.femaleCount, unit: ''},
            {name: '小骆驼', value: obj.childCount, unit: ''},
            {name: '其他', value: obj.otherCount, unit: ''},
          ]
        } 

        this.cattleCategoryChartData = {...this.cattleCategoryChartData}
      },

      goToPage(path) {
        this.$router.push({
          path: path
        })
      },
    }
  }
</script>

<style>
.el-pagination__total { color: #fff; }
</style>

